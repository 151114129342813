import styled, { css } from 'styled-components';
import { Grid } from '@/styles/generic/container';
import { gap, gutters, spacing } from '@/styles/settings/spacing';
import { above } from '@/styles/tools/media';
import breakpoints from '../../../styles/settings/breakpoints';
import {
	smBodyBook,
	smBodyLight,
	smBodyRegular,
	lgTitleRegular,
} from '../../atoms/Typography/index.styled';

const LogoGrid__container = styled(Grid)`
	margin-top: ${spacing[48]};
	margin-bottom: ${spacing[48]};

	${above(
	breakpoints[960],
	() => css`
			margin-top: ${spacing[64]};
			margin-bottom: ${spacing[64]};
		`
)}

	${above(
	breakpoints[1440],
	() => css`
			margin-top: ${spacing[80]};
			margin-bottom: ${spacing[80]};
		`
)}
`;

const LogoGrid = styled.div`
	grid-column: 1 / -1;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: -${gap[16]};
	margin-right: -${gap[8]};
	margin-left: -${gap[8]};
	padding-left: ${gap[0]};

	${above(
	breakpoints[960],
	() => css`
			margin-top: -${gap[24]};
			margin-right: -${gap[12]};
			margin-left: -${gap[12]};
		`
)}

	${above(
	breakpoints[1920],
	() => css`
			margin-top: -${gap[32]};
			margin-right: -${gap[16]};
			margin-left: -${gap[16]};
		`
)}
`;

const LogoGrid__Item = styled.div`
	display: none;
	position: relative;
	width: calc(50% - ${gutters[16]});
	margin-top: ${gap[16]};
	margin-right: ${gap[8]};
	margin-left: ${gap[8]};

	&:nth-child(-n + 6) {
		display: block;
	}

	${above(
	breakpoints[600],
	() => css`
			display: block;
			width: calc(25% - ${gutters[16]});
		`
)}

	${above(
	breakpoints[960],
	() => css`
			width: calc(16.6667% - ${gutters[24]});
			margin-top: ${gap[24]};
			margin-right: ${gap[12]};
			margin-left: ${gap[12]};
		`
)}

	${above(
	breakpoints[1920],
	() => css`
			width: calc(16.6667% - ${gutters[32]});
			margin-top: ${gap[32]};
			margin-right: ${gap[16]};
			margin-left: ${gap[16]};
		`
)}
`;

const LogoGrid__ItemInner = styled.div`
	padding-top: 100%;
	border: 1px solid var(--secondaryBackground);
`;

const LogoGrid__Picture = styled.picture`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: ${gap[16]};

	& > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const LogoGrid__Title = styled(lgTitleRegular)`
	grid-column: 1 / -1;

	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);

	${above(
	breakpoints[960],
	() => css`
			text-align: center;
			margin-bottom: ${gap[40]};
		`
)}
`;

const LogoGrid__Body = styled(smBodyRegular)`
	grid-column: 1 / -1;

	margin-top: ${gap[24]};
	color: var(--primaryForeground);
	text-align: center;

	${above(
	breakpoints[960],
	() => css`
			margin-top: ${gap[40]};
		`
)}
`;

const LogoGrid__logoLists = styled.div`
	grid-column: 1 / -1;

	display: flex;
	justify-content: space-between;
	margin-top: ${gap[32]};
	padding-left: ${gap[0]};

	${above(
	breakpoints[600],
	() => css`
			margin-top: ${gap[40]};
		`
)}
`;

const LogoGrid__logoList = styled.div`
	width: 100%;
	text-align: center;
	display: none;

	&:nth-child(1) {
		display: block;
	}

	${above(
	breakpoints[600],
	() => css`
			width: calc(50% - 12px);

			&:nth-child(2) {
				display: block;
			}
		`
)}

	${above(
	breakpoints[960],
	() => css`
			width: calc(33.3% - 16px);
			display: block;
		`
)}
`;

const LogoGrid__logoCopy = styled(smBodyBook)`
	margin-bottom: ${gap[8]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}
`;

type CopyProps = {
	isWide?: boolean
};

const LogoGrid__Copy = styled(smBodyLight) <CopyProps>`
	grid-column: 1 / -1;

	margin-bottom: 24px;

	
	${props => above(breakpoints[960], () => css`
		grid-column: ${() => props.isWide ? '3 / -3' : '4 / -4'};

		text-align: center;
		margin-top: 8px;
		margin-bottom: 38px;
	`)}
`;

export default {
	LogoGrid__container,
	LogoGrid,
	LogoGrid__Item,
	LogoGrid__Title,
	LogoGrid__Body,
	LogoGrid__Picture,
	LogoGrid__ItemInner,
	LogoGrid__logoLists,
	LogoGrid__logoList,
	LogoGrid__logoCopy,
	LogoGrid__Copy,
};
