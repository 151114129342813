import React from 'react';
import { clientConfig } from '@/env/config';
import Styled from './index.styled';

const path = clientConfig().mediaServer;

type ImageTypes = {
	id: string;
	src: string;
	alt: string;
	imageType?: string;
};

type LogoGridTypes = {
	heading?: string;
	copy?: string;
	body?: string;
	images: Array<ImageTypes>;
	logoTextLists: Array<any>;
	wideCopy?: boolean;
	anchorId?: string;
};

function buildSrc(rootPath: string, src: string, imageType?: string) {
	if (src.includes('images.ctfassets.net')) {
		return `https:${src}?fm=jpg&fl=progressive`;
	}
	if (src.includes('frontify')) {
		return `https:${src}?format=webp&width=320`;
	}

	return rootPath + src + (imageType ? `.${imageType}` : '.png');
}

function buildSrcSet(rootPath: string, src: string, imageType?: string) {
	if (src.includes('images.ctfassets.net')) {
		return `https:${src}?fm=jpg&fl=progressive`;
	}
	if (src.includes('frontify')) {
		return `https:${src}?format=webp&width=320`;
	}
	return `${rootPath + src + (imageType ? `.${imageType}` : '.png')}?imageformat=webp`;
}

const LogoLists = ({ logoTextLists }) => (
	<Styled.LogoGrid__logoLists as="ul" aria-label="Other Bruntwood partners">
		{logoTextLists &&
			logoTextLists.map((logoTextList, index) => (
				<Styled.LogoGrid__logoList key={index} as="li">
					{logoTextList.map((logoText, i) => (
						<Styled.LogoGrid__logoCopy key={i}>{logoText}</Styled.LogoGrid__logoCopy>
					))}
				</Styled.LogoGrid__logoList>
			))}
	</Styled.LogoGrid__logoLists>
);

const LogoImages = ({ images, body }) => (
	<>
		<Styled.LogoGrid as="ul" aria-label="Bruntwood partner network">
			{images.map((image: ImageTypes) => (
				<Styled.LogoGrid__Item key={image.id} as="li">
					<Styled.LogoGrid__ItemInner>
						<Styled.LogoGrid__Picture>
							{path && (
								<>
									<source
										type="image/webp"
										data-srcset={buildSrcSet(path, image.src, image.imageType)}
									/>
									<img
										className="lazyload"
										data-src={buildSrc(path, image.src, image.imageType)}
										alt={image.alt}
									/>
								</>
							)}
						</Styled.LogoGrid__Picture>
					</Styled.LogoGrid__ItemInner>
				</Styled.LogoGrid__Item>
			))}
		</Styled.LogoGrid>

		{body && <Styled.LogoGrid__Body>{body}</Styled.LogoGrid__Body>}
	</>
);

const LogoGrid = ({
	heading,
	copy,
	body,
	images,
	logoTextLists,
	wideCopy,
	anchorId,
}: LogoGridTypes) => (
	<Styled.LogoGrid__container as="section" id={anchorId || ''}>
		{heading && <Styled.LogoGrid__Title as="h2">{heading}</Styled.LogoGrid__Title>}

		{copy && <Styled.LogoGrid__Copy isWide={wideCopy}>{copy}</Styled.LogoGrid__Copy>}

		<LogoImages images={images} body={body} />

		{logoTextLists && <LogoLists logoTextLists={logoTextLists} />}
	</Styled.LogoGrid__container>
);

export default LogoGrid;
